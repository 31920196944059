<template>
    <v-card flat class="">
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr>
						<th>Institusi</th>
						<td>: {{ data.nama_institusi }}</td>
						<td></td>
						<th>Program Studi</th>
						<td>: {{ data.nama_prodi }}</td>
					</tr>
                    <tr>
                        <th>Kelompok</th>
                        <td>: {{ data.profesi }}</td>
                        <td></td>
                        <th>Tanggal Awal</th>
                        <td>: {{ formatDate(data.tanggal_awal) }}</td>
                    </tr>
                    <tr>
                        <th>Stase</th>
                        <td>: {{ data.nama }}, {{ data.stase }}</td>
                        <td></td>
                        <th>Tanggal Akhir</th>
                        <td>: {{ formatDate(data.tanggal_akhir) }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-container fluid>
            <h3>Verifikasi Daftar Hadir</h3>
            <v-row>
                <v-col cols="4" md="2">
                    <v-subheader>Tanggal:</v-subheader>
                </v-col>
                <v-col cols="4" md="2">
                    <v-text-field v-model="form.editedItem.tanggal" @change="getDataFromApi()" type="date" dense outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="4" md="2">
                    <v-btn color="primary" small @click="getDataFromApi()">Filter</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Tanggal</th>
                        <th>Jam Datang</th>
                        <th>Jam Pulang</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in table.items" :key="item.id">
                        <td>{{ idx + 1 }}</td>
                        <td>{{ item.nama }}</td>
                        <td>{{ formatDate(item.tanggal) }}</td>
                        <td>{{ item.jam_datang }}</td>
                        <td>{{ item.jam_pulang }}</td>
                        <td>
                            <v-checkbox v-if="item.verifikasi_pembimbing_at == null" v-model="form.editedItem.ids" x-small
                                :value="item.id" dense hide-details=""></v-checkbox>
                            <span v-else>
                                <v-chip x-small color="success">Verified</v-chip>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="5"></td>
                    <td><v-btn color="primary" small @click="save()">Simpan</v-btn></td>
                </tfoot>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
// import PilihMultiUser from '@/views/PilihMultiUser.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        // PilihMultiUser,
    },
    data() {
        return {
            praktik_id: this.$route.params.praktik_id,
            data: {
                nama_institusi: '',
				nama_prodi: '',
                profesi: '',
                stase: '',
                tanggal_awal: '',
                tanggal_akhir: '',
            },
            icons: {
                mdiDelete,
                mdiPencil,
            },
            tableOptions: {},
            table: {
                loading: true,
                // headers: [
                //     { text: 'No', align: 'start', sortable: false, value: 'no', },
                //     { text: 'Waktu', value: 'waktu', sortable: false },
                //     { text: 'Kegiatan', value: 'kegiatan', sortable: false },
                //     { text: '', value: 'actions', sortable: false },
                // ],
                items: [],
                totalItems: 0,
            },
            dialog: false,
            form: {
                editedIndex: -1,
                editedItem: {
                    tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
                    ids: [],
                },
                defaultItem: {
                    tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
                    ids: [],
                },
                pembimbings: [],
            },
        }
    },
    watch: {
        // tableOptions: {
        //     handler() {
        //         this.getDataFromApi()
        //     },
        //     deep: true,
        // },
    },
    computed: {
        formTitle() {
            return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
        },
    },
    created() {
        this.getDetail()
        this.getDataFromApi()
    },
    methods: {
        save() {
            if (this.form.editedIndex > -1) {
                // const fmData = new FormData()
                // fmData.append('id', this.form.editedItem.id)
                // fmData.append('pembimbing_id', this.form.editedItem.pembimbing_id)
                // fmData.append('kegiatan', this.form.editedItem.kegiatan)
                // axiosPostAuth('api/Pembimbing/updateLogbook', fmData)
                //     .then(response => {
                //         if (response.code === 200) {
                //             this.getDataFromApi()
                //             this.dialog = false
                //         }
                //         else {
                //             alert(response.message)
                //         }
                //     })
                //     .catch(error => {
                //         alert(error)
                //     })
            } else {
                const fmData = new FormData()
                fmData.append('praktik_id', this.praktik_id)
                fmData.append('ids', this.form.editedItem.ids)
                axiosPostAuth('api/Pembimbing/verifikasiDaftarHadir', fmData)
                    .then(response => {
                        if (response.code === 200) {
                            this.getDataFromApi()
                            this.dialog = false
                        }
                        else {
                            alert(response.message)
                        }
                    })
                    .catch(error => {
                        alert(error)
                    })
            }
        },
        getDataFromApi() {
            const fmData = new FormData()
            fmData.append('praktik_id', this.praktik_id)
            fmData.append('tanggal', this.form.editedItem.tanggal)
            axiosPostAuth('api/Pembimbing/getDaftarHadir', fmData)
                .then(response => {
                    this.table.items = response.data
                    this.table.totalItems = response.total
                    this.table.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
        add() {
            this.form.editedItem = Object.assign({}, this.form.defaultItem)
            this.form.editedIndex = -1
        },
        editItem(item) {
            this.form.editedIndex = this.table.items.indexOf(item)
            this.form.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        getDetail() {
            const fmData = new FormData()
            fmData.append('id', this.praktik_id)
            axiosPostAuth('api/Praktik/getPraktik', fmData)
                .then(response => {
                    this.data = Object.assign({}, response.data)
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>